import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';
import Link from 'components/shared/link';

import styles from './investors.module.scss';

const cx = classNames.bind(styles);

const Investors = ({ title, items }) => (
  <section className={cx('wrapper')}>
    <div className="container">
      <Heading className={cx('title')} size="xl">
        {title}
      </Heading>

      <ul className={cx('items-wrapper')}>
        {items.map(({ name, url }, index) => (
          <li className={cx('item')} key={index}>
            {url ? (
              <Link className={cx('item-link')} to={url}>
                {name}
              </Link>
            ) : (
              name
            )}
          </li>
        ))}
      </ul>
    </div>
  </section>
);

Investors.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ).isRequired,
};

export default Investors;
