import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';

import shape1 from './images/shape-1.svg';
import shape2 from './images/shape-2.svg';
import dotPattern from './images/dot-pattern.svg';

import styles from './hero.module.scss';

const cx = classNames.bind(styles);

const Hero = ({ title, subtitle, images }) => (
  <section className={cx('wrapper')}>
    <div className="container">
      <Heading className={cx('title')}>{title}</Heading>
      <Heading className={cx('subtitle')} tag="p" size="lg" role="doc-subtitle">
        {subtitle}
      </Heading>

      <div className={cx('images-wrapper')}>
        {images.map((image, index) => (
          <GatsbyImage
            className={cx('image')}
            loading="eager"
            image={getImage(image.localFile.childImageSharp)}
            aria-hidden
            alt={image.altText || image.title}
            key={index}
          />
        ))}
      </div>

      <img className={cx('shape-1')} src={shape1} alt="" loading="lazy" aria-hidden />
      <img className={cx('shape-2')} src={shape2} alt="" loading="lazy" aria-hidden />
      <img className={cx('dot-pattern')} src={dotPattern} alt="" loading="lazy" aria-hidden />
    </div>
  </section>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      localFile: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.shape({
            aspectRatio: PropTypes.number.isRequired,
            src: PropTypes.string.isRequired,
            srcSet: PropTypes.string.isRequired,
            sizes: PropTypes.string.isRequired,
            base64: PropTypes.string,
            tracedSVG: PropTypes.string,
            srcWebp: PropTypes.string,
            srcSetWebp: PropTypes.string,
            media: PropTypes.string,
          }),
        }),
      }),
    })
  ).isRequired,
};

export default Hero;
