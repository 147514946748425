import React from 'react';
import PropTypes from 'prop-types';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';
import Link from 'components/shared/link';
import styles from './team-members-gallery.module.scss';

import LinkedinIcon from './images/linkedin.inline.svg';

const cx = classNames.bind(styles);

const TeamMembersGallery = ({ items }) => (
  <section className={cx('wrapper')}>
    <div className="container">
      <div className={cx('items-wrapper')}>
        {items.map(({ photo, name, position, linkedin }, index) => (
          <div className={cx('item')} key={index}>
            <div className={cx('item-photo-wrapper')}>
              {linkedin && (
                <Link className={cx('item-link')} to={linkedin}>
                  <LinkedinIcon />
                </Link>
              )}
              <GatsbyImage
                className={cx('item-photo')}
                image={getImage(photo.localFile.childImageSharp)}
                alt={photo.altText}
              />
            </div>

            <div className={cx('item-content')}>
              <Heading className={cx('item-title')} size="lg">
                {name}
              </Heading>
              <span className={cx('item-position')}>{position}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

TeamMembersGallery.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.shape({
        localFile: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              aspectRatio: PropTypes.number.isRequired,
              src: PropTypes.string.isRequired,
              srcSet: PropTypes.string.isRequired,
              sizes: PropTypes.string.isRequired,
              base64: PropTypes.string,
              tracedSVG: PropTypes.string,
              srcWebp: PropTypes.string,
              srcSetWebp: PropTypes.string,
              media: PropTypes.string,
            }),
          }),
        }),
      }).isRequired,
      name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      linkedin: PropTypes.string,
    })
  ).isRequired,
};

export default TeamMembersGallery;
