import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';
import Button from 'components/shared/button';

import shape1 from './svg/shape-1.svg';
import shape2 from './svg/shape-2.svg';
import dotPattern from './svg/dot-pattern.svg';

import styles from './get-quote.module.scss';

const cx = classNames.bind(styles);

const GetQuote = () => {
  const {
    wpSharedBlock: {
      acf: { title, description, buttonText, buttonUrl },
    },
  } = useStaticQuery(
    graphql`
      query {
        wpSharedBlock(slug: { eq: "get-a-quote" }) {
          acf {
            title
            description
            buttonText
            buttonUrl
          }
        }
      }
    `
  );

  return (
    <section className={cx('wrapper')}>
      <div className="container">
        <Heading className={cx('title')} tag="h2" size="xl" color="tertiary">
          {title}
        </Heading>
        <p className={cx('description')}>{description}</p>
        <Button className={cx('button')} to={buttonUrl}>
          {buttonText}
        </Button>

        <img className={cx('shape-1')} src={shape1} alt="" />
        <img className={cx('shape-2')} src={shape2} alt="" />
        <img className={cx('dot-pattern')} src={dotPattern} alt="" />
      </div>
    </section>
  );
};

export default GetQuote;
