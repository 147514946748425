import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames/bind';

import ProductContent from 'components/shared/product-content';
import shape from './images/shape.svg';

import styles from './deliberate.module.scss';

const cx = classNames.bind(styles);

const Deliberate = (props) => {
  const { illustration } = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "about-us/deliberate/illustration.png" }) {
        childImageSharp {
          gatsbyImageData(width: 640)
        }
      }
    }
  `);

  return (
    <section className={cx('wrapper')}>
      <div className="container">
        <div className="columns">
          <div className="column is-12-mobile is-6-tablet is-5-desktop">
            <ProductContent iconName="phone" withIcon={false} {...props} />
          </div>
        </div>

        <div className={cx('illustration')} aria-hidden>
          <img className={cx('shape')} src={shape} alt="" />
          <GatsbyImage image={getImage(illustration)} alt="" />
        </div>
      </div>
    </section>
  );
};

Deliberate.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
};

Deliberate.defaultProps = {
  buttonText: null,
  buttonUrl: null,
};

export default Deliberate;
